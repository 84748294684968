import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router'

const Selection = lazy(() => import('./pages/Selection'))
const VenteSelectionnee = lazy(() => import('./pages/VenteSelectionnee'))

export const PATHS = {
  SELECTION: '/selections/:selectionId',
  VENTE_SELECTIONNEE:
    '/selections/:selectionId/ventes-selectionnees/:venteSelectionneeId',
}

const routes = () => (
  <Suspense fallback={null}>
    <Switch>
      <Route exact path={PATHS.SELECTION} render={() => <Selection />} />
      <Route
        exact
        path={PATHS.VENTE_SELECTIONNEE}
        render={() => <VenteSelectionnee />}
      />
    </Switch>
  </Suspense>
)

export default routes
