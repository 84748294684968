import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'

import { VenteSelectionneeEntities } from './ventesSelectionnees.types'

export type BiensSelectionesState = Readonly<{
  entities: VenteSelectionneeEntities
}>

const initialState: BiensSelectionesState = { entities: {} }

const ventesSelectionneesSlice = createSlice({
  name: 'BiensSelectionnes',
  initialState,
  reducers: {
    addOrUpdateBiensSelectionnes: (
      state,
      { payload }: PayloadAction<VenteSelectionneeEntities>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
  },
})

export const { addOrUpdateBiensSelectionnes } = ventesSelectionneesSlice.actions
export default ventesSelectionneesSlice.reducer
