import { Sprite } from '@apimmo/front/components/icons/Sprite'
import React, { ReactNode } from 'react'
import { GlobalStyle } from '../style/GlobalStyle'

const Root = ({ children }: { children: ReactNode }) => (
  <>
    <GlobalStyle />
    <Sprite />
    {children}
  </>
)

export default Root
