import { colors as frontColors } from '@apimmo/front/style/theme'
import { remCalc } from '@apimmo/front/utils/selectors'
import { icons } from './icons'

export const colors = {
  ...frontColors,
} as const

export type Color = keyof typeof colors

export const maxWidth = remCalc(1200)

export type Icon = keyof typeof icons
