import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { Store } from 'redux'
import { Persistor } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import ErrorBoundary from '~/components/ErrorBoundary'
import Root from './components/Root'
import Routes from './routes'

interface Props {
  persistor: Persistor
  store: Store
}

const App = ({ persistor, store }: Props) => (
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Root>
            <Routes />
          </Root>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
)

export default App
