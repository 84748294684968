import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'

import { SelectionEntities } from './selections.types'

export type SelectionsState = Readonly<{
  entities: SelectionEntities
}>

const initialState: SelectionsState = { entities: {} }

const selectionsSlice = createSlice({
  name: 'Selections',
  initialState,
  reducers: {
    addOrUpdateSelections: (
      state,
      { payload }: PayloadAction<SelectionEntities>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
  },
})

export const { addOrUpdateSelections } = selectionsSlice.actions
export default selectionsSlice.reducer
