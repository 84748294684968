import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'

import { CollaborateurEntities } from './collaborateurs.types'

export type CollaborateursState = Readonly<{
  entities: CollaborateurEntities
}>

const initialState: CollaborateursState = { entities: {} }

const collaborateursSlice = createSlice({
  name: 'Collaborateurs',
  initialState,
  reducers: {
    addOrUpdateCollaborateurs: (
      state,
      { payload }: PayloadAction<CollaborateurEntities>,
    ) => {
      state.entities = mergeDeepRight(state.entities, payload)
    },
  },
})

export const { addOrUpdateCollaborateurs } = collaborateursSlice.actions
export default collaborateursSlice.reducer
