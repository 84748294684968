/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.ts, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux'

import collaborateurs from './collaborateurs/collaborateurs.slice'
import selections from './selections/selections.slice'
import ventesSelectionnees from './ventesSelectionnees/ventesSelectionnees.slice'
import { RootState } from './types'

export default combineReducers<RootState>({
  collaborateurs,
  selections,
  ventesSelectionnees,
})
