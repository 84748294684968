import React, { Component } from 'react'

type ErrorBoundaryState = {
  error: Error | null
}

class ErrorBoundary extends Component<unknown, ErrorBoundaryState> {
  public state = {
    error: null,
  }

  public static getDerivedStateFromError(error: Error) {
    return { error }
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo)
  }

  public render() {
    const { children } = this.props
    const { error } = this.state

    if (error) {
      return 'Une erreur est survenue 🙊'
    }

    return children
  }
}

export default ErrorBoundary
